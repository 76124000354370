import { useEffect, useRef, useState } from "react"
import { NumberInput } from "../../../shared/components/inputs"

export const PriceInput = ({
  defaultValue = 0,
  // focus,
  onChange,
}: {
  defaultValue?: number
  // focus?: boolean
  onChange?: (price) => void
}) => {
  const [value, setValue] = useState<number>(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const inputTxt = useRef(null)
  // inputTxt.current?.blur()
  // useEffect(() => {
  //   if (focus) {
  //     inputTxt.current?.select()
  //     inputTxt.current?.focus()
  //   } else inputTxt.current?.blur()
  // }, [focus])

  return (
    <NumberInput
      ref={inputTxt}
      value={value}
      onChange={(e) => {
        setValue(e)
      }}
      onBlur={(e) => {
        onChange?.(Number(e.target.value) * 100)
      }}
    />
  )
}
