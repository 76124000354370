type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}

export function mergeObjects<T>(obj1: T, obj2: DeepPartial<T>): T {
  const mergedObject = { ...obj1 }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (
        typeof obj2[key] === "object" &&
        obj2[key] !== null &&
        !Array.isArray(obj2[key])
      ) {
        mergedObject[key] = mergeObjects(obj1[key], obj2[key]) as T[Extract<
          keyof T,
          string
        >]
      } else {
        mergedObject[key] = obj2[key] as T[Extract<keyof T, string>]
      }
    }
  }

  return mergedObject
}
