import { selectedSpaceAtom } from "@/atoms/spaces"
import { selectedTask } from "@/atoms/tasks"
import { FilePreviewModal } from "@/components/FilePreview"
import { BasicButton } from "@/components/buttons/BasicButton"
import { DownloadCVParams } from "@/types/cvpartner"
import { downloadCV } from "@/utils/api/cvpartners"
import { useUser } from "hooks/cvpartner/useUser"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { toast } from "sonner"
import { ICell } from "../../Cell"

export const CVCell = (props: ICell) => {
  const { user: profile } = useUser(
    !props?.value ? props?.record?._user?.cvp_id : undefined,
  )
  const [open, setOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cv, setCv] = useState<string>()
  const space = useRecoilValue(selectedSpaceAtom)
  const task = useRecoilValue(selectedTask)
  const cvTemplateField = task?._template?.fields?.find(
    (f) => f.type === "cv_templates",
  )
  const cvTemplate = task?.[cvTemplateField?.key]
  const router = useRouter()

  useEffect(() => {
    if (open && !cv) {
      getCV()
    }
  }, [open])

  const getCV = async () => {
    setIsLoading(true)
    const cv = props?.value?.id ?? profile?.default_cv_id
    const user = props?.value?.user_id ?? profile?.id
    const lang = space.locale?.split?.("-")?.[1]?.toLowerCase() ?? "int"
    const args: DownloadCVParams = {
      cv,
      user,
      filetype: "pdf",
      lang,
    }
    if (cvTemplate || profile?.default_cv_template_id) {
      args["template"] = cvTemplate ?? profile?.default_cv_template_id
    }
    try {
      const res = await downloadCV(args)
      if (res?.cv) {
        setCv(res?.cv)
      } else {
        toast.error("No CV found")
      }
    } catch (error) {
      toast.error("No CV found")
    } finally {
      setIsLoading(false)
    }
  }

  if (!profile && !props?.value && !isLoading) {
    return <p className="text-gray-200">No CV</p>
  }

  return (
    <div>
      <FilePreviewModal
        header={{
          title: profile?.name,
          handlers: (
            <BasicButton
              variant="primary"
              className=""
              onClick={() => router.replace(`/people/${props.record.uid}`)}
            >
              Go to profile
            </BasicButton>
          ),
        }}
        open={!isLoading && open}
        setOpen={setOpen}
        loading={isLoading}
        uris={cv ? [{ uri: cv, mimetype: "application/pdf" }] : undefined}
      />
      <button
        type="button"
        className={`underline ${isLoading ? "animate-wiggle-pulse-blue" : ""}`}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}
      >
        View CV
      </button>
    </div>
  )
}
