import { fetcher } from "../index"

/**
 *  @deprecated This method is a cop-out. Try to make a more relevant API call instead
 */
export const updateNodeContent = async (node, content = {}) => {
  return await fetcher(`/nodes/${node}`, {
    method: "PUT",
    body: JSON.stringify({ ...content }),
  })
}

export const deleteNode = async (node, soft) => {
  return await fetcher(`/nodes/${node}`, {
    method: "DELETE",
    body: JSON.stringify({ soft }),
  })
}
