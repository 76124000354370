import { CVPUser } from "@/types/cvpartner"
import { fetcher } from "@/utils/api"
import useSWRImmutable from "swr/immutable"

export const useUser = (id: string) => {
  const {
    data: user,
    isValidating,
    isLoading,
    error,
  } = useSWRImmutable<CVPUser>(id ? `/cvpartners/users/${id}` : null, fetcher)
  return {
    user,
    isLoading,
    isValidating,
    error,
  }
}
