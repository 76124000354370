import { ICell } from "../../Cell"

export const UserTypeCell = ({ value }: ICell) => {
  return (
    <div
      style={{
        background:
          value === "INTERNAL" ? "rgb(134 239 172)" : "rgb(252, 165, 165)",
      }}
      className={`red w-fit cursor-pointer rounded-md px-2 py-1 text-center text-[12px] font-medium capitalize text-white`}
    >
      {value.replace("_", " ")}
    </div>
  )
}
