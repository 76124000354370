import { atom, selector } from "recoil"
import { fetcher } from "../../utils/api"

export const atomCurrencies = atom({
  key: "currencies",
  default: selector({
    key: "currencies/selector",
    get: async () => {
      return await fetcher("/currencies")
    },
  }),
})
