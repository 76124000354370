import { useSourceTags } from "hooks/queries/tagQueries"
import { useRef } from "react"
import { toast } from "sonner"
import { AddTagButton } from "../../tags/components/AddTagButton"
import { TagsViewer } from "../../tags/components/TagsViewer"

type TagsRenderProps = {
  source: string
  space?: string
  editable: boolean
  selected?: any
  group?: any
}

export const TagsRender = ({
  source,
  space,
  editable = true,
  selected,
  group,
}: TagsRenderProps) => {
  const {
    tags: _tags,
    setTags,
    invalidateTags,
  } = useSourceTags(source, { space })
  const containerRef = useRef(null)

  const tagsByGroup =
    _tags?.filter(
      (tag) =>
        (group === "other" && tag.groups.length === 0) ||
        (group ? tag.groups.some((g) => g.uid === group) : true),
    ) || []

  return (
    <div className="flex gap-2" ref={containerRef}>
      {editable && (tagsByGroup.length as Number) === 0 && (
        <AddTagButton
          open={selected}
          group={group}
          onSelect={(tag) => {
            setTags((prev) => {
              if (prev?.find((item) => item.uid === tag.uid)) return prev
              return [...(prev || []), tag]
            })
            toast.success("Tag added successfully")
          }}
          source={source}
          onClose={() => {
            containerRef.current?.focus()
          }}
        />
      )}
      {editable && (tagsByGroup.length as Number) !== 0 && (
        <AddTagButton
          open={selected}
          group={group}
          hoverEffect={true}
          onSelect={(tag) => {
            setTags((prev) => {
              if (prev?.find((item) => item.uid === tag.uid)) return prev
              return [...(prev || []), tag]
            })
            toast.success("Tag added successfully")
          }}
          source={source}
          onClose={() => {
            containerRef.current?.focus()
          }}
        />
      )}

      <TagsViewer
        source={editable ? source : null}
        onRemove={
          editable
            ? () => {
                invalidateTags()
              }
            : undefined
        }
        shownAmount={3}
        tags={tagsByGroup}
      />
    </div>
  )
}
