import { IPrice } from "@/features/candidateflow/types"
import {
  getCatalogues,
  getCataloguesPrices,
} from "@/features/pricings/utils/api"
import { getTagsBySources } from "@/features/tags/utils/getTagsBySources"
import { useQuery, useQueryClient } from "@tanstack/react-query"

export const usePriceTags = (uid: string) => {
  const { data: tags, ...rest } = useQuery({
    queryKey: ["price/tags", uid],
    queryFn: async () => {
      const getTags = await getTagsBySources([uid])
      return getTags[uid] ?? []
    },
  })
  return { tags, ...rest }
}

export const usePriceCatalogues = (space?: string) => {
  const { data: catalogues, ...rest } = useQuery({
    queryKey: ["price/catalogues", space],
    queryFn: () => getCatalogues(space),
    initialData: [],
  })
  return { catalogues, ...rest }
}

export const useCatalougePrices = (uid: string, opts?: { space?: string }) => {
  const { data: prices, ...rest } = useQuery({
    queryKey: ["price/catalogue/prices", uid, opts],
    queryFn: () => getCataloguesPrices(uid, opts),
    initialData: [],
  })
  const queryClient = useQueryClient()

  const setPrices = (arg: IPrice[] | ((old: IPrice[]) => IPrice[])) => {
    queryClient.setQueryData(["price/catalogue/prices", uid, opts], arg)
  }

  return { prices, setPrices, ...rest }
}
