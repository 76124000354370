import { fetcher } from "@/api/index"
import { postAppData, SPACES_APP } from "../apps"
import { PREFERRED_SUPPLIERS_APP } from "./../apps/index"

export const getSuppliersByTask = async (task: string) => {
  return await fetcher(`/tasks/${task}/suppliers`)
}

// export const assignSuppliers = ({ task, suppliers }) => {
//   return fetcher(`/tasks/${task}/suppliers`, {
//     method: "POST",
//     body: JSON.stringify({
//       suppliers,
//     }),
//   })
// }
export const assignSuppliers = async ({
  task,
  suppliers,
}: {
  task: string
  suppliers: any[]
}) => {
  try {
    const response = await fetcher(`/tasks/${task}/suppliers`, {
      method: "POST",
      body: JSON.stringify({ suppliers }),
    })
    return response
  } catch (error) {
    console.error("Error assigning suppliers:", error)
    throw error
  }
}

// export const createVendor = ({ reference, emails = [] }) => {
//   return fetcher(`/suppliers`, {
//     method: "POST",
//     body: JSON.stringify({
//       reference,
//       emails,
//     }),
//   })
// }
export const createVendor = async ({
  reference,
  emails = [],
}: {
  reference: string
  emails?: string[]
}) => {
  try {
    const response = await fetcher(`/suppliers`, {
      method: "POST",
      body: JSON.stringify({
        reference,
        emails,
      }),
    })
    return response
  } catch (error) {
    console.error("Error creating vendor:", error)
    throw error
  }
}

export const createSupplierSpace = async ({
  name,
  reference,
}: {
  name: string
  reference: string
}) => {
  const createdSpace = await postAppData(SPACES_APP, {
    name,
    reference,
    categories: ["Supplier", "Organisation"],
  })

  const addPrefferedSuppliers = await postAppData(PREFERRED_SUPPLIERS_APP, {
    spaces: [createdSpace.uid],
  })

  return addPrefferedSuppliers
}
