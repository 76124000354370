import { IOption } from "@/features/datasources/types"
import { HEX_OPACITY } from "@/utils/constants"

export const OptionPill = ({ option }: { option: IOption }) => {
  return (
    <div
      style={{
        backgroundColor: option.color + HEX_OPACITY[10],
        color: option.color,
      }}
      className={"w-fit select-none rounded-xl px-2 py-1 text-xs"}
    >
      <p>{option.text}</p>
    </div>
  )
}
