"use client"

import * as React from "react"
import { CaptionProps, DayPicker, useNavigation } from "react-day-picker"

import { Button, buttonVariants } from "@/shared/components/ui/button"
import { cn } from "@/utils/helpers"
import {
  faChevronLeft,
  faChevronsLeft,
  faChevronRight,
  faChevronsRight,
} from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"

function CustomCaptionComponent(props: CaptionProps) {
  const { goToMonth, goToDate, nextMonth, previousMonth } = useNavigation()

  const nextYear = () => {
    const nextYear = dayjs(props.displayMonth)
      .add(1, "year")
      .subtract(1, "month")
      .toDate()
    goToDate(nextYear)
  }

  const previousYear = () => {
    const prevYear = dayjs(props.displayMonth)
      .subtract(1, "year")
      .subtract(1, "month")
      .toDate()
    goToDate(prevYear)
  }

  return (
    <div className="flex items-center justify-center">
      <Button
        size="icon"
        className="absolute left-4 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        disabled={!previousMonth}
        onClick={() => previousMonth && previousYear()}
      >
        <FontAwesomeIcon icon={faChevronsLeft} size="sm" />
      </Button>
      <Button
        size="icon"
        className="absolute left-12 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <FontAwesomeIcon icon={faChevronLeft} size="sm" />
      </Button>
      <p className="text-sm font-medium capitalize">
        {dayjs(props.displayMonth).format("MMMM YYYY")}
      </p>
      <Button
        size="icon"
        className="absolute right-12 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        <FontAwesomeIcon icon={faChevronRight} size="sm" />
      </Button>
      <Button
        size="icon"
        className="absolute right-4 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        disabled={!previousMonth}
        onClick={() => previousMonth && nextYear()}
      >
        <FontAwesomeIcon icon={faChevronsRight} size="sm" />
      </Button>
    </div>
  )
}

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-gray-300 rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: cn(
          "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-gray-200 [&:has([aria-selected].day-outside)]:bg-gray-100 [&:has([aria-selected].day-range-end)]:rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
            : "[&:has([aria-selected])]:rounded-md",
        ),
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100",
        ),
        day_range_end: "day-range-end",
        day_selected:
          "bg-blue-500 text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white",
        day_today: "bg-gray-200",
        day_outside:
          "day-outside text-gray-200 opacity-50 aria-selected:bg-blue-500 aria-selected:text-gray-800 aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-gray-200 aria-selected:text-gray-800",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <FontAwesomeIcon icon={faChevronLeft} />,
        IconRight: ({ ...props }) => <FontAwesomeIcon icon={faChevronRight} />,
        ...(props?.mode === "range" ? { Caption: CustomCaptionComponent } : {}),
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
