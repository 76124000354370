import { CVPUser } from "@/types/cvpartner"

export const getProfileLink = (user: CVPUser | undefined) => {
  if (!user) return null
  const subdomain = user.company_subdomains[0]
  const { id, default_cv_id } = user
  return `https://${subdomain}.cvpartner.com/dashboard#persons/proposal/false/cv/${id}/${default_cv_id}/read_only/default`
}

export const getCustomCVLink = (
  cvId: string,
  proposalId: string,
  userId: string,
  subdomain: string,
) => {
  return `https://${subdomain}.cvpartner.com/dashboard#persons/proposal/${proposalId}/cv/${userId}/${cvId}/`
}
