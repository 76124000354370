import { useEffect, useState } from "react"
import { useRecoilValueLoadable } from "recoil"
import { atomCurrencies } from "../../../atoms/currencies"
import { DropdownGeneric } from "../../../shared/components/fields"
import { DropdownItem } from "../../../shared/components/items/DropdownItem"
import { Icon } from "../../icons/components/IconLoader"

type ICurrency = {
  uid: string
  name: string
  prefix?: string
  symbol?: string
  exchange_rates?: {
    [key: string]: number
  }
  contries?: string[]
}

export const CurrencyRender = ({
  currency_uid,
  editable,
  onChange,
}: {
  currency_uid: string
  editable: boolean
  onChange?: (uid: string) => void
}) => {
  const { contents: currencies, state: currenciesState } =
    useRecoilValueLoadable(atomCurrencies)
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency>(null)

  useEffect(() => {
    if (currenciesState !== "hasValue") return

    setSelectedCurrency(currencies.find((c) => c.uid === currency_uid))
  }, [currencies])

  if (currenciesState !== "hasValue") return <></>

  if (editable) {
    return (
      <DropdownGeneric<ICurrency>
        collection={currencies}
        open={true}
        onKeyDown={(e, item) => {
          if (e.code === "Enter") {
            e.preventDefault()
            onChange?.(item.uid)
            setSelectedCurrency(item)
          }
        }}
        renderItem={({ item, isHighlighted }) => (
          <div
            tabIndex={0}
            className={`${isHighlighted ? "rounded-lg bg-neutral-100" : ""}`}
            key={item.uid}
          >
            <DropdownItem
              onClick={() => {
                onChange?.(item.uid)
                setSelectedCurrency(item)
              }}
              title={item.prefix}
            />
          </div>
        )}
        button={
          <div className="flex items-center justify-between rounded-xl border-b-2 bg-white px-4 py-2">
            {selectedCurrency?.prefix}
            <Icon icon={"faChevronDown"} />
          </div>
        }
      />
    )
  }

  return <>{selectedCurrency?.prefix}</>
}
